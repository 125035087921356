import React, { useState, useEffect, FC } from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'

import { ButtonDS, AccordionDS } from '../'
import {
    JotaSocialYoutube as YoutubeIcon,
    JotaSocialFacebook as FacebookIcon,
    JotaSocialInstagram as InstagramIcon,
    JotaSocialLinkedin as LinkedinIcon,
    JotaSocialTwitter as TwitterIcon,
    JotaSocialSpotifyPodcast as SpotifyPodcastIcon,
    tokens,
    JotaMaterialOpenNew
} from '@JOTAJornalismo/jota-design-system'
import { FooterIH } from '../../interfaces'
import { AUTH_CONSTANT, LAYOUT_CONSTANT } from '../../utils/constants'
import { useSelector } from 'react-redux'
import { StoreType } from '@App/api/types/store'
import staticFooterMenu from './footerlinks'
const { colors, fonts, spaces } = tokens

/**
 * Footer Component
 * @return {JSX.Element}
 */
export const { SM_PX, MD_PX, LG_PX } = LAYOUT_CONSTANT.BREAKPOINTS

type staticMenuType = {
    columnTitle: string
    columnItens: columnItensType[]
}

type columnItensType = {
    title: string
    url: string
    openInNewTab: boolean
}

const desktopItems = (staticMenu: staticMenuType[]) => {
    return staticMenu.map((column) => (
        <LinksContainer key={column.columnTitle}>
            <Title>{column.columnTitle}</Title>
            {column.columnItens.map((columnItem) => (
                <Item
                    key={columnItem.title}
                    data-test='footer-open-new-tab'
                    onClick={() =>
                        window.open(
                            columnItem.url,
                            columnItem.openInNewTab ? '_blank' : '_self'
                        )
                    }
                >
                    {columnItem.title}
                    {columnItem.openInNewTab ? (
                        <JotaMaterialOpenNew
                            fill={colors.transparent.jotaTBlack24}
                        />
                    ) : (
                        ''
                    )}
                </Item>
            ))}
        </LinksContainer>
    ))
}

const mobileItems = (staticMenu: staticMenuType[]) => {
    return staticMenu.map((column) => (
        <div key={column.columnTitle} style={{ width: '100%' }}>
            <AccordionDS
                key={column.columnTitle}
                id={column.columnTitle}
                label={column.columnTitle}
                type='first'
                hasBorder
                RightIcon={true}
                onClick={() => {
                    const childs = document.getElementsByClassName(
                        column.columnTitle
                    ) as HTMLCollectionOf<HTMLElement>

                    if (childs[0].style.display == 'block') {
                        childs[0].style.display = 'none'
                    } else {
                        childs[0].style.display = 'block'
                    }
                    return {}
                }}
            />
            <AccordionContainer className={column.columnTitle}>
                {column.columnItens.map((item) => (
                    <AccordionDS
                        key={item.title}
                        id={item.title}
                        label={item.title}
                        type='second'
                        hasBorder
                        RightIcon={
                            item.openInNewTab ? 'jotaMaterialOpenNew' : ''
                        }
                        onClick={() => {
                            window.open(
                                item.url,
                                item.openInNewTab ? '_blank' : '_self'
                            )
                            return {}
                        }}
                    />
                ))}
            </AccordionContainer>
        </div>
    ))
}

const AccordionContainer = styled.div`
    display: none;
    width: 100%;

    & > div {
        box-shadow: none;
    }
`

const FooterComponent: FC<{ footer: FooterIH }> = ({
    footer
}): React.JSX.Element => {
    const [windowWidth, setWindowWidth] = useState<any>()
    const { auth } = useSelector((state: StoreType) => state)
    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', () => handleResize())
    }, [footer.menu])

    return (
        <FooterContainer>
            <GrayContainer>
                <CTA id='footer-cta-container'>
                    <Image
                        src={`${process.env.NEXT_PUBLIC_JU}/images/logo-jota-121.svg`}
                        alt='jota'
                        width={121}
                        height={45}
                    />
                    <Paragraph
                        isNotLogged={
                            auth?.authState !=
                            AUTH_CONSTANT.AUTH_STATE.USER_LOGGED
                        }
                    >
                        Nossa missão é tornar as instituições brasileiras mais
                        previsíveis.
                    </Paragraph>
                    {auth?.authState !=
                        AUTH_CONSTANT.AUTH_STATE.USER_LOGGED && (
                        <div>
                            <ButtonDS
                                id='jota-pro-footer'
                                label={footer.cta}
                                size='medium'
                                color='black'
                                width={
                                    windowWidth > 1199 || windowWidth < 500
                                        ? '100%'
                                        : 'auto'
                                }
                                type='filled'
                                onClick={() =>
                                    window.open(`${footer.url}`, '_blank')
                                }
                            />
                        </div>
                    )}
                </CTA>
                <Sitemap id='sitemap-editorials'>
                    {windowWidth > 1200 ? (
                        <Section data-test='sitemap-section'>
                            {desktopItems(staticFooterMenu)}
                        </Section>
                    ) : (
                        <>
                            <Section data-test='sitemap-section'>
                                {mobileItems(staticFooterMenu)}
                            </Section>
                        </>
                    )}
                </Sitemap>
            </GrayContainer>
            <BlackContainer>
                <List>
                    <ListItem>
                        <ListItemLink href={footer.paginaFAQ} target='_blank'>
                            FAQ{' '}
                            <JotaMaterialOpenNew
                                fill={colors.transparent.jotaTWhite50}
                            />
                        </ListItemLink>
                        <Separator>|</Separator>
                    </ListItem>
                    <ListItem>
                        <ListItemLink
                            href={footer.paginaContato}
                            target='_blank'
                        >
                            Contato{' '}
                            <JotaMaterialOpenNew
                                fill={colors.transparent.jotaTWhite50}
                            />
                        </ListItemLink>
                        <Separator>|</Separator>
                    </ListItem>
                    <ListItem>
                        <ListItemLink
                            href={footer.paginaTrabalhe}
                            target='_blank'
                        >
                            Trabalhe Conosco{' '}
                            <JotaMaterialOpenNew
                                fill={colors.transparent.jotaTWhite50}
                            />
                        </ListItemLink>
                    </ListItem>
                </List>
                {windowWidth <= 1200 && (
                    <Follow style={{ margin: 0 }}>Siga o Jota</Follow>
                )}
                <IconContainer>
                    {windowWidth > 1200 && (
                        <Follow style={{ margin: 0 }}>Siga o Jota</Follow>
                    )}
                    <YoutubeIcon
                        type='white'
                        onClick={() =>
                            window.open(`${footer.paginaRedeYoutube}`, '_blank')
                        }
                    />
                    <FacebookIcon
                        type='white'
                        onClick={() =>
                            window.open(
                                `${footer.paginaRedeFacebook}`,
                                '_blank'
                            )
                        }
                    />
                    <InstagramIcon
                        type='white'
                        onClick={() =>
                            window.open(
                                `${footer.paginaRedeInstagram}`,
                                '_blank'
                            )
                        }
                    />
                    <LinkedinIcon
                        type='white'
                        onClick={() =>
                            window.open(
                                `${footer.paginaRedeLinkedIn}`,
                                '_blank'
                            )
                        }
                    />
                    <TwitterIcon
                        type='white'
                        onClick={() =>
                            window.open(`${footer.paginaRedeTwitter}`, '_blank')
                        }
                    />
                    <SpotifyPodcastIcon
                        type='white'
                        onClick={() =>
                            window.open(`${footer.paginaRedeSpotify}`, '_blank')
                        }
                    />
                </IconContainer>
            </BlackContainer>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
`
const GrayContainer = styled.div`
    padding: 44px;
    display: flex;
    gap: 60px;
    background-color: ${colors.transparent.jotaTGrayLight40};

    @media only screen and (max-width: ${MD_PX}) {
        flex-direction: column;
        padding: 44px 36px;
    }

    @media only screen and (max-width: ${SM_PX}) {
        flex-direction: column;
        padding: 44px 16px;
    }
`
const CTA = styled.div`
    max-width: 272px;

    @media only screen and (max-width: ${MD_PX}) {
        max-width: 100%;
    }

    @media only screen and (max-width: ${SM_PX}) {
        max-width: 100%;
    }
`
const Paragraph = styled.p<{ isNotLogged: boolean }>`
    ${fonts.jotaBody()};
    color: ${colors.gray.jotaGrayDark};
    padding: ${(props) => (props.isNotLogged ? '24px 0 44px 0' : '24px 0 0 0')};
    margin: 0;
`
const Sitemap = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 72px;
    width: 100%;

    @media only screen and (max-width: ${MD_PX}) {
        flex-direction: column;
    }

    @media only screen and (max-width: ${SM_PX}) {
        flex-direction: column;
    }
`
const Section = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 72px;
    max-height: 380px;

    @media only screen and (max-width: ${MD_PX}) {
        flex-direction: column;
        display: flex;
        width: 100%;
        max-height: inherit;
        gap: 0;
    }
`

const LinksContainer = styled.div``

const Title = styled.h5`
    ${fonts.jotaOverline()};
    color: ${colors.gray.jotaGrayDarker};
    margin: 0;
    padding-bottom: 16px;
`
const Item = styled.p`
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 16px;
    ${fonts.jotaBodySmall};
    color: ${colors.gray.jotaGrayDarker};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    & > svg {
        width: 16px;
        margin-left: 6px;
        height: 16px;
    }
`
const BlackContainer = styled.div`
    padding: ${spaces.jotaSpacing24} ${spaces.jotaSpacing44};
    background-color: ${colors.black.jotaBlackLight};
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${MD_PX}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 36px;
        padding: ${spaces.jotaSpacing24} ${spaces.jotaSpacing36};
    }

    @media only screen and (max-width: ${SM_PX}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 36px;
        padding: ${spaces.jotaSpacing24} ${spaces.jotaSpacing16};
    }
`
const List = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;
`
const ListItem = styled.li`
    list-style-type: none;
    display: flex;
    align-items: center;
`
const ListItemLink = styled.a`
    ${fonts.jotaBody()};
    color: ${colors.white.jotaWhite};
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    & > svg {
        width: 16px;
        margin-left: 6px;
        height: 16px;
    }
`
const Separator = styled.span`
    ${fonts.jotaBody()};
    color: ${colors.transparent.jotaTGrayLight40};
    margin: 0;
    padding: 0 12px;
`

const Follow = styled.p`
    ${fonts.jotaOverline()};
    color: ${colors.transparent.jotaTWhite50};
    margin: 0;
    padding: 0;
`

const IconContainer = styled.div`
    ${fonts.jotaOverline()};
    color: ${colors.transparent.jotaTWhite50};
    margin: 0;
    padding: 0;
    display: flex;
    gap: 24px;
    align-items: center;
    cursor: pointer;

    & > p {
        cursor: none;
    }
`

export default FooterComponent
